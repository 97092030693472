import React, { useState, useRef, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";

// Define the keyframes for the fade-in and pop animation
const fadeInAndPop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.95);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

// The animation mixin that can be applied to any component
const fadeInAndPopAnimation = css`
  animation: ${fadeInAndPop} 0.5s ease-in-out forwards; // Increased duration and changed to ease-in-out
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  opacity: 0;
  ${(props) =>
    props.isVisible &&
    fadeInAndPopAnimation}; // Apply the fade-in and pop animation when the component is visible

  @media (max-width: 500px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  z-index: -1;
`;

export const Center = styled.div`
  margin: auto;
  @media (max-width: 1300px) {
    max-width: calc(100vw - 100px);
  }
  @media (max-width: 500px) {
    max-width: calc(100vw - 60px);
  }
`;

export const Main = styled.div`
  max-width: ${(props) => props.maxWidth}px;
`;

export default function BasicComponent({
  children,
  maxWidth = 1200,
  style = {},
}) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Unobserve the element after it's been revealed
          observer.unobserve(containerRef.current);
        }
      },
      {
        root: null, // The viewport
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Container ref={containerRef} isVisible={isVisible} style={style}>
      <Center>
        <Main maxWidth={maxWidth}>{children}</Main>
      </Center>
    </Container>
  );
}
