import React from "react";
import TextCardContainer from "../components/Cards/TextCardContainer";
import ImageHeaderAlt from "../components/Headers/ImageHeaderAlt";
import QuadGraphicCTA from "../components/General/QuadGraphicCTA";
import ImageCTA from "../components/Headers/ImageCTA";

import { FaArrowRight } from "react-icons/fa";
import DualGraphicCTA from "../components/General/DualGraphicCTA";
import BasicComponent from "../components/General/BasicComponenet";

const cards = [
  {
    title: "Strategic Management",
    description:
      "We offer guidance and strategic planning to align with your organizational goals, ensuring every step we take together is intentional and effective.",
  },
  {
    title: "Community Engagement",
    description:
      "By fostering strong community relations and outreach, we help you build a foundation of support that sustains and enhances your initiatives.",
  },
  {
    title: "Creative Communications",
    description:
      "Our team produces creative content that stands out. From impactful videos to engaging social media content, we ensure your message is heard.",
  },
  {
    title: "Brand Building",
    description:
      "Beyond logos and slogans, we build identities that embody your values and mission, creating a lasting impression in the educational sector.",
  },
  {
    title: "Educational Alliances",
    description:
      "We understand the nuances of educational environments, which is why we tailor our strategies to meet the specific needs of school districts and nonprofits.",
  },
  {
    title: "Professional Development",
    description:
      "We offer comprehensive programs and training to enhance your skills and knowledge, ensuring that you stay at the forefront of your field and achieve your career goals.",
  },
];

const ctaData = [
  {
    title: "Adaptable for Every Learner",
    description:
      "We understand that learning is a personal experience. That's why our offerings are fully customizable to meet the diverse needs of every student and educator. We cater all our services to match the needs of our clients.",
  },
  {
    title: "Tailored to Empower",
    description:
      "We believe in the power of education to inspire change. Our programs are crafted to fit every budget without compromising on quality or accessibility. Each project is a step towards achieving our educational aspirations.",
  },
  {
    title: "Achievement Through Innovation",
    description:
      "Our commitment is to guide you to your learning goals with cutting-edge educational technology that adapts to individual needs and promotes collective success.",
  },
];

export default function Home() {
  return (
    <>
      <BasicComponent style={{ marginBottom: -50 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              color: "#281188",
              fontWeight: "bold",
              fontSize: "3rem",
              textAlign: "center",
            }}
          >
            GOYE AI
          </span>{" "}
          <span
            style={{
              color: "#281188",
              fontSize: "2rem",
              textAlign: "center",
            }}
          >
            Elevating Education & Nonprofits
          </span>
        </div>
      </BasicComponent>
      <ImageCTA
        url={"/pexel/pexels-rdne-stock-project-8500287.jpg"}
        title="Connecting Education and Nonprofits with People, Skills, Tools, and Spaces for Empowerment"
        subTitle="We understand that learning is a personal experience. That's why our offerings are fully customizable to meet the diverse needs of every student and educator. We cater all our services to match the needs of our clients."
        ctaLink="/resources"
        ctaTitle={
          <span style={{}}>
            Teacher?{" "}
            <span style={{ textDecorationLine: "underline" }}>Click Here </span>
            <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
      />
      <DualGraphicCTA
        ctaLink="/services"
        data={ctaData}
        ctaTitle="Learn how we can help"
      />
      <TextCardContainer
        cards={cards}
        title="Our Holistic Approach"
        subtitle="Integrating Comprehensive Strategies for Educational and Nonprofit Excellence"
      />
      <ImageHeaderAlt
        url={"/pexel/pexels-kampus-production-5940837.jpg"}
        title="Our Mission"
        subtitle="Our Mission: To enhance the educational experience by providing tailored technology solutions and training that prioritize personal learning journeys, social justice, and collaborative design with educators, students, and user at the center."
      />
      <QuadGraphicCTA
        title="Jumpstart a transformative educational journey"
        subTitle="Our learning solutions are designed to seamlessly integrate into your everyday life."
        ctaLink="/contact"
        ctaTitle="Get in touch"
        data={ctaData}
        url={"/pexel/pexels-max-fischer-5212700.jpg"}
      />
      <ImageCTA
        title="Interested in learning more?"
        subTitle="We understand that learning is a personal experience. That's why our offerings are fully customizable to meet the diverse needs of every student and educator. We cater all our services to match the needs of our clients."
        ctaLink="/services"
        ctaTitle={
          <span>
            Our services <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/pexel/pexels-fauxels-3184437.jpg"
      />
    </>
  );
}
