import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { IoIosArrowForward } from "react-icons/io"; // Import arrow icon
import BasicComponent from "../General/BasicComponenet";

const BREAK_1 = 1200;
const BREAK_2 = 700;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px; // Add space between inputs
  display: flex;
  flex-direction: column;
  padding: 50px 200px 50px 200px;
  border-radius: 5px;
  background-color: #281188; // Updated background color
  width: calc(100vw);
  max-width: calc(${BREAK_1}px - 400px);

  @media (max-width: ${BREAK_1}px) {
    max-width: calc(100vw - 200px);
    padding: 50px 50px 50px 50px;
  }

  @media (max-width: ${BREAK_2}px) {
    max-width: calc(100vw - 160px);
    padding: 50px 50px 50px 50px;
  }
`;

const StyledInput = styled.input`
  padding: 15px; // Increased padding
  margin-bottom: 20px; // Increased space between fields
  background-color: #fff; // White background
  border: none; // No border
  border-radius: 5px;
  font-size: 16px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); // Inner shadow for depth
`;

const StyledTextArea = styled.textarea`
  padding: 15px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  resize: none; // Disable resizing to match the image
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 120px; // Fixed height
`;

const StyledButton = styled.button`
  padding: 15px 20px;
  background-color: #e0e0e0;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; // Space between text and icon

  &:hover {
    background-image: ; // Darken on hover
  }

  svg {
    transition: transform 0.2s ease; // Animation for the arrow
  }

  &:hover svg {
    transform: translateX(5px); // Move arrow on hover
  }
`;

const StyledTitle = styled.div`
  font-size: 3rem;
  padding: 50px;
  text-align: center;
  font-weight: 700;
  color: #281188;

  @media (max-width: ${BREAK_2}px) {
    text-align: center;
    padding: 20px 20px 20px 20px;
    font-size: 2rem;
  }
`;

const ContactForm = ({
  buttonText = "Submit",
  title = "Let's get in touch",
}) => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send email via EmailJS
    emailjs
      .send(
        "service_qif4d0o",
        "template_sh9qmii",
        formData,
        "aR9XBz1Klidyr3vMM"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({ name: "", email: "", message: "" }); // Reset form after successful submission
        },
        (error) => {
          console.error("FAILED...", error);
        }
      );
  };

  return (
    <BasicComponent>
      <StyledForm onSubmit={handleSubmit}>
        <span
          style={{ color: "#fff", fontSize: "1.25rem", fontWeight: "bold" }}
        >
          Contact Form
        </span>
        <StyledInput
          type="text"
          name="from_name"
          placeholder="First Name"
          value={formData.from_name}
          onChange={handleChange}
        />
        <StyledInput
          type="text"
          name="from_last_name" // Assuming you want to capture last name
          placeholder="Last Name"
          value={formData.from_last_name} // Ensure you handle this in state
          onChange={handleChange}
        />
        <StyledInput
          type="text"
          name="from_email" // Assuming you want to capture last name
          placeholder="Email Address"
          value={formData.from_email} // Ensure you handle this in state
          onChange={handleChange}
        />
        <StyledTextArea
          name="message"
          placeholder="Notes"
          value={formData.message}
          onChange={handleChange}
        />
        <StyledButton type="submit">
          {buttonText}
          <IoIosArrowForward /> {/* Icon component */}
        </StyledButton>
      </StyledForm>
    </BasicComponent>
  );
};

export default ContactForm;
