import React from "react";

import styled from "styled-components";
import BasicComponenet from "../General/BasicComponenet";

const BREAK_1 = 1200;
const BREAK_2 = 700;

const Title = styled.div`
  font-size: 1.75rem;
  color: #281188;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  padding-top: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 20px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  width: 32.2%;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: ${BREAK_1}px) {
    width: 48.3%;
  }

  @media (max-width: ${BREAK_2}px) {
    width: 100%;
  }
`;

const CardTitle = styled.span`
  font-size: 1rem;
  color: #281188;
  font-weight: 700;
  padding-left: 20px;
`;

const CardDescription = styled.span`
  font-size: 1rem;
  color: #000;
  padding: 20px;
`;

export default function TextCardContainer({
  title = "Enter title prop...",
  subtitle = "Enter subtitle prop...",
  cards = [
    {
      title: "Card Title",
      description:
        "Enter a description for this card using the description field.",
    },
    {
      title: "Card Title",
      description:
        "Enter a description for this card using the description field.",
    },
    {
      title: "Card Title",
      description:
        "Enter a description for this card using the description field.",
    },
    {
      title: "Card Title",
      description:
        "Enter a description for this card using the description field.",
    },
    {
      title: "Card Title",
      description:
        "Enter a description for this card using the description field.",
    },
    {
      title: "Card Title",
      description:
        "Enter a description for this card using the description field.",
    },
  ],
}) {
  return (
    <BasicComponenet>
      <Container>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <CardContainer>
          {cards.map((card) => (
            <Card>
              <CardTitle>{card.title.split(" ")[0]}</CardTitle>
              {card.title.split(" ")[1] && (
                <CardTitle>{card.title.split(" ")[1]}</CardTitle>
              )}
              <CardDescription>{card.description}</CardDescription>
            </Card>
          ))}
        </CardContainer>
      </Container>
    </BasicComponenet>
  );
}
