import React from "react";
import QuadGraphicCTA from "../components/General/QuadGraphicCTA";
import IconCardSection from "../components/Cards/IconCardSection";
import { FaArrowRight } from "react-icons/fa";
import { MdCastForEducation, MdTerminal } from "react-icons/md";
import { ImBooks } from "react-icons/im";
import { GiPlatform } from "react-icons/gi";
import { MdInsights } from "react-icons/md";
import { HiChatAlt2 } from "react-icons/hi";
import { FaChildReaching } from "react-icons/fa6";
import { GiMoneyStack } from "react-icons/gi";
import { MdAppShortcut } from "react-icons/md";
import { RiParentFill } from "react-icons/ri";

import ImageCTA from "../components/Headers/ImageCTA";

const ctaData = [
  {
    title: "Pioneering Advanced Education and Nonprofit Solutions",
    description:
      "At GOYE AI, we harness the synergy of app and AI development to revolutionize educational and nonprofit initiatives. Our suite of strategic services is designed to connect diverse talents and empower organizations through innovation. \nWe are your partner in educational and nonprofit excellence, equipping you with strategic tools to foster growth, innovation, and success.",
  },
  {
    title: "Interactive Learning Reinvented",
    description:
      "Each application is embedded with AI that adapts to individual learning styles, providing a customized educational journey for maximum efficacy.",
  },
  {
    title: "Outreach and Fundraising Transformed",
    description:
      "We build comprehensive platforms that integrate seamlessly with multiple communication channels, from social media to direct messaging, ensuring impactful community engagement.",
  },
];

const sections = [
  {
    title: "Development Portfolio",
    cards: [
      /*{
        icon: (
          <img
            style={{ height: 200, width: 200 }}
            src="/logos/new-icon.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        link: "https://getlitnow.org",
        title: "GetLit",
        description:
          "Dive into a transformative educational platform where teachers can seamlessly integrate trending media like TikToks and YouTube videos into their lesson boards. Based on the unique content of each board, whether it's literature or current news, GetLit curates engaging media, fostering a dynamic learning environment. Students can then interact, comment, like, and vote on these media-rich sections, making learning more interactive and contemporary.",
      },*/
      {
        icon: (
          <img
            style={{ height: 120, width: 120, borderRadius: 60 }}
            src="/logos/icon.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        link: "https://1907collection.com/",
        title: "Shop 1907",
        description:
          "1907 collection was created to bring life to DILLARD HIGH's (FTL) rich tradition, community, and pride through fashion. We celebrate those who have come before us and seek to support those coming through— This clothing brand is your one-stop shop for the latest DILLARD (FTL) gear. Yes, you can still buy from any other vendors selling DILLARD apparel and accessories. However, this brand is creating, developing, and connecting ways to give back to the student community. We are a for profit company with a giving heart",
      },
    ],
  },
  {
    title: "Educational Tools",
    cards: [
      {
        icon: <MdCastForEducation />,
        title: "Engaging Educational Apps",
        description:
          "We design and develop interactive applications and lessons specifically tailored for educational purposes, incorporating elements like quizzes, multimodal interactive literacy, Interactive Digital Narrative, VR, and gamified learning to enhance student engagement and retention.",
      },
      {
        icon: <MdTerminal />,
        title: "AI-Enhanced Learning",
        description:
          "Our applications integrate artificial intelligence to personalize the learning experience, adapting to individual learning styles and pacing, thereby optimizing the educational process for each user.",
      },
      {
        icon: <ImBooks />,
        title: "Authoring tools",
        description:
          "We explore and develop authoring tools to offer alternative learning options.",
      },
      {
        icon: <GiPlatform />,
        title: "Flexible Platforms",
        description:
          "We create customizable learning platforms that can be tailored to fit the unique curriculum and teaching methodologies of educational institutions.",
      },
      {
        icon: <MdInsights />,
        title: "Analytics and Insights",
        description:
          "These platforms include advanced analytics to track student performance, providing valuable insights to educators for targeted teaching strategies.",
      },
    ],
  },
  {
    title: "Customer or Parent Communication",
    cards: [
      {
        icon: <HiChatAlt2 />,
        title: "AI-Driven Communication Tools",
        description:
          "Automated Responses: Utilize AI-powered chatbots and automated response systems to handle inquiries, providing quick and accurate information to students, parents, or donors. Personalized Interactions: Our AI tools can personalize communication based on user history and preferences, enhancing the overall experience and fostering stronger relationships.",
      },
      {
        icon: <FaChildReaching />,
        title: "Engagement and Outreach Platforms",
        description:
          "Multi-Channel Communication: Develop platforms that facilitate communication across various channels like email, social media, and messaging apps, ensuring effective outreach and engagement with the community.",
      },
      {
        icon: <RiParentFill />,
        title: "Parent Training",
        description:
          "We provide comprehensive training and resources to parents, helping them navigate and utilize digital tools effectively. This initiative is aimed at strengthening the bridge between digital learning and parental involvement, ensuring that parents are well-equipped to support their children's educational journey in today's technology-driven world.",
      },
    ],
  },
  {
    title: "AI Fundraising Platforms",
    cards: [
      {
        icon: <GiMoneyStack />,
        title: "AI-Enhanced Fundraising Tools",
        description:
          "Donor Insights: Use AI to analyze donor data and gain insights into giving patterns, helping to tailor fundraising strategies and campaigns effectively. Targeted Campaigns: Implement AI algorithms to create targeted fundraising campaigns that resonate with potential donors, increasing the likelihood of donations.",
      },
      {
        icon: <MdAppShortcut />,
        title: "Integrated Fundraising Applications",
        description:
          "Seamless Donation Experience: Design user-friendly fundraising applications that make the donation process simple and efficient for users. Real-Time Reporting: Include features for real-time tracking and reporting of fundraising campaigns, providing transparency and insights to optimize future efforts.",
      },
    ],
  },
];

export default function AIServicesPage() {
  return (
    <>
      <QuadGraphicCTA
        title="GOYE AI Solutions"
        subTitle=""
        ctaLink="/contact"
        ctaTitle="Get in touch"
        data={ctaData}
        url="/pexel/pexels-fauxels-3184468.jpg"
      />
      <IconCardSection
        title="Strategic Services for Education and Nonprofit Organizations: App and AI Development"
        url="/pexel/pexels-julia-m-cameron-4143801.jpg"
        sections={sections}
        imgAlt="two logos side by side, left is for GetLit, right is for Shop 1907"
      />
      <ImageCTA
        title="Interested in an AI Solution?"
        subTitle="We are dedicated to empowering educational and nonprofit entities through strategic and creative communication solutions. We understand that at the heart of every successful initiative lies a compelling narrative. Our goal is to help you define, articulate, and disseminate your unique story to build lasting connections and achieve sustainable engagement."
        ctaLink="/services"
        ctaTitle={
          <span>
            Get in touch <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/pexel/pexels-fauxels-3184419.jpg"
      />
    </>
  );
}
