import React from "react";

import styled from "styled-components";
import BasicComponenet from "./BasicComponenet";

const BREAK_2 = 700;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #fff, #f8f8f8);
  padding: 20px;
  margin: -20px;
`;

const DualContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: ${BREAK_2}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`;

const Title = styled.div`
  font-size: 1.25rem;
  color: #281188;
  font-weight: 600;

  @media (max-width: ${BREAK_2}px) {
    text-align: left;
  }
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: #000;

  @media (max-width: ${BREAK_2}px) {
    text-align: left;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #dad6e8;
  border-radius: 5px;
  max-width: calc(50% - 53px);
  padding: 30px 20px 30px 20px;
  gap: 20px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

  @media (max-width: ${BREAK_2}px) {
    max-width: 100%;
  }
`;

const CTAContainer = styled.div`
  margin: auto;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (max-width: ${BREAK_2}px) {
  }
`;

export default function DualGraphicCTA({
  data = [
    { title: "floater 1 title", description: "floater 1 description" },
    { title: "bottom left title", description: "bottom left description..." },
    { title: "bottom right title", description: "bottom right description..." },
  ],
  ctaLink = "/contact",
  ctaTitle = "Get in touch",
}) {
  return (
    <BasicComponenet>
      <Container>
        <DualContainer>
          <BottomContainer>
            <Title>{data[1].title}</Title>
            <SubTitle>{data[1].description}</SubTitle>
          </BottomContainer>
          <BottomContainer>
            <Title>{data[2].title}</Title>
            <SubTitle>{data[2].description}</SubTitle>
          </BottomContainer>
        </DualContainer>
        <CTAContainer>
          <div
            style={{
              backgroundColor: "#281188",
              padding: 20,
              borderRadius: 5,
              textDecoration: "none",
            }}
          >
            <a
              style={{
                color: "#fff",
                textDecoration: "none",
                fontWeight: "500",
                fontSize: 18,
              }}
              href={ctaLink}
            >
              {ctaTitle}
            </a>
          </div>
        </CTAContainer>
      </Container>
    </BasicComponenet>
  );
}
