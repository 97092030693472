import React from "react";

import styled from "styled-components";
import BasicComponenet from "./BasicComponenet";

const BREAK_2 = 700;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #fff, #f8f8f8);
  padding: 20px;
  margin: -20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  text-align: center;

  @media (max-width: ${BREAK_2}px) {
    margin-bottom: 40px;
  }
`;

const QuadContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: ${BREAK_2}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`;

const Image = styled.img`
  height: 450px;
  width: 800px;
  max-width: calc(50% - 15px);
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  @media (max-width: ${BREAK_2}px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

const Title = styled.div`
  font-size: 1.25rem;
  color: #281188;
  font-weight: 600;

  @media (max-width: ${BREAK_2}px) {
    text-align: left;
  }
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: #000;

  @media (max-width: ${BREAK_2}px) {
    text-align: left;
  }
`;

const FloaterContainer = styled.div`
  display: flex;
  max-width: calc(50% - 53px);

  @media (max-width: ${BREAK_2}px) {
    max-width: 100%;
  }
`;

const FloaterInside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 100px;
  max-width: 75%;

  @media (max-width: ${BREAK_2}px) {
    margin-top: 0px;
    max-width: 100%;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #dad6e8;
  border-radius: 5px;
  max-width: calc(50% - 53px);
  padding: 30px 20px 30px 20px;
  gap: 20px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

  @media (max-width: ${BREAK_2}px) {
    max-width: 100%;
  }
`;

const CTAContainer = styled.div`
  margin: auto;
  padding-top: 30px;
  padding-bottom: 20px;

  @media (max-width: ${BREAK_2}px) {
  }
`;

export default function QuadGraphicCTA({
  title,
  subTitle,
  data = [
    { title: "floater 1 title", description: "floater 1 description" },
    { title: "bottom left title", description: "bottom left description..." },
    { title: "bottom right title", description: "bottom right description..." },
  ],
  ctaTitle = "Get in touch",
  url = "https://img.freepik.com/free-vector/page-found-concept-illustration_114360-1869.jpg",
  alt = "Enter image alt text...",
}) {
  return (
    <BasicComponenet>
      <Container>
        {(title || subTitle) && (
          <TitleContainer>
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
          </TitleContainer>
        )}
        <QuadContainer>
          <FloaterContainer>
            <FloaterInside>
              <Title>{data[0].title}</Title>
              <SubTitle>{data[0].description}</SubTitle>
            </FloaterInside>
          </FloaterContainer>
          <Image alt={alt} src={url}></Image>
          <BottomContainer>
            <Title>{data[1].title}</Title>
            <SubTitle>{data[1].description}</SubTitle>
          </BottomContainer>
          <BottomContainer>
            <Title>{data[2].title}</Title>
            <SubTitle>{data[2].description}</SubTitle>
          </BottomContainer>
        </QuadContainer>
        <CTAContainer>
          <div
            style={{
              backgroundColor: "#281188",
              padding: 20,
              borderRadius: 5,
              textDecoration: "none",
            }}
          >
            <a
              style={{
                color: "#fff",
                textDecoration: "none",
                fontWeight: "500",
                fontSize: 18,
              }}
              href={"/contact"}
            >
              {ctaTitle}
            </a>
          </div>
        </CTAContainer>
      </Container>
    </BasicComponenet>
  );
}
