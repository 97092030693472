import React from "react";
import IconCardSection from "../components/Cards/IconCardSection";
import { FaArrowRight } from "react-icons/fa";
import { AiFillAppstore, AiOutlineBarChart } from "react-icons/ai";
import { MdTranslate } from "react-icons/md";
import {
  FaChalkboardTeacher,
  FaUsers,
  FaNetworkWired,
  FaGraduationCap,
} from "react-icons/fa";
import { FaHandshake, FaUniversity } from "react-icons/fa";
import { GiArtificialIntelligence } from "react-icons/gi";
import { GiTeacher, GiRobotGolem } from "react-icons/gi";
import {
  SiAdobeaftereffects,
  SiAdobepremierepro,
  SiMdbook,
} from "react-icons/si";
import { TfiBlackboard } from "react-icons/tfi";
import { MdSchool } from "react-icons/md";
import { SiAdobephotoshop } from "react-icons/si";
import { FaProjectDiagram } from "react-icons/fa";

import ImageCTA from "../components/Headers/ImageCTA";
import QuadGraphicCTA from "../components/General/QuadGraphicCTA";

const ctaData = [
  {
    title: "As the founder of GOYE LLC (2010)",
    description:
      "Mistoria B. specializes in merging advanced technology with educational initiatives, aiming to make a significant impact in the Artificial Intelligence sector, particularly focusing on equitable solutions. Her core mission is to harness technology, specifically AI, to empower and educate individuals from all backgrounds, with a special emphasis on marginalized communities.",
  },
  {
    title: "Educational Innovation and Equity",
    description:
      "With a strong foundation in educational technology and AI, champions equitable learning solutions. Her work is grounded in research and real-world applications, focusing on creating inclusive education environments through innovative AI applications that cater to the needs of diverse learners",
  },
  {
    title: "Leadership in Technological Advancement",
    description:
      "Leveraging a holistic leadership style, she excels in fostering strategic partnerships across the AI and education sectors. Her approach intertwines technology with cultural insights, ensuring that educational programs are both technologically advanced and culturally responsive.",
  },
];

const professionalExpertiseAndAchievements = [
  {
    title: "Skills",
    cards: [
      {
        icon: <AiFillAppstore />,
        title: "Educational App Development",
        description:
          "Expertise in creating AI-integrated, user-friendly educational applications.",
      },
      {
        icon: <MdTranslate />,
        title: "AI and Applied Linguistics",
        description:
          "Experience in blending AI with educational initiatives, enhancing learning experiences.",
      },
      {
        icon: <FaChalkboardTeacher />,
        title: "Innovative Communication Strategies",
        description:
          "Skill in merging technology with cultural and spiritual insights for effective communication.",
      },
    ],
  },
  {
    title: "Professionalism",
    cards: [
      {
        icon: <FaHandshake />,
        title: "Stakeholder Engagement",
        description:
          "Proficiency in engaging with diverse stakeholders including educators and technologists.",
      },
      {
        icon: <FaProjectDiagram />,
        title: "Learning Program Managemer",
        description:
          "Expertise in managing educational content, curriculum design, and project implementation.",
      },
      {
        icon: <FaUsers />,
        title: "Team Management",
        description:
          "Leadership in fostering collaborative environments and overseeing team performance.",
      },
    ],
  },
  {
    title: "Research",
    cards: [
      {
        icon: <AiOutlineBarChart />,
        title: "STEM Equity Research",
        description:
          "Involvement in research focusing on STEM equity and education for underrepresented groups.",
      },
      {
        icon: <GiArtificialIntelligence />,
        title: "AI Education Research",
        description:
          "Directing research on AI applications in educational contexts.",
      },
    ],
  },
  {
    title: "Affiliations",
    cards: [
      {
        icon: <FaNetworkWired />,
        title: "Association for Research in Digital Interactive Narratives",
        description:
          "Membership in an association dedicated to digital narratives research.",
      },
      {
        icon: <FaGraduationCap />,
        title: "Literacy Research Association",
        description:
          "Student membership in a literacy research-focused association.",
      },
    ],
  },
];

const academicCredentialsAndLearning = [
  {
    title: "Education",
    cards: [
      {
        icon: <FaUniversity />,
        title: "Bachelor's Degrees",
        description:
          "BA in Theater and BS in Communication from Florida Atlantic University.",
      },
      {
        icon: <MdSchool />,
        title: "Master's Degree",
        description:
          "Master in Education and Social Change from the University of Miami.",
      },
      {
        icon: <FaGraduationCap />,
        title: "PhD Specialization",
        description:
          "Pursuing a PhD focusing on Language, Literacy Learning in Multicultural Settings at the University of Miami.",
      },
    ],
  },
  {
    title: "Certifications",
    cards: [
      /*{
        icon: (
          <img
            src="companies/sba.png"
            style={{ width: 200, height: 150, objectFit: "contain" }}
          />
        ),
        title: "8(a) Certificate",
        description: "SBA 8(a) Certificate",
      },
      {
        icon: (
          <img
            src="companies/wosb.png"
            style={{ width: 200, height: 150, objectFit: "contain" }}
          />
        ),
        title: "WOSB Certificate",
        description: "Certified WBEN Women's Business Enterprise",
      },
      {
        icon: (
          <img
            src="companies/wmbe.png"
            style={{ width: 200, height: 150, objectFit: "contain" }}
          />
        ),
        title: "OSD-WMBE Certificate",
        description: "Certified Minority Owned Business",
      },*/
      {
        icon: <SiAdobephotoshop />,
        title: "Adobe Photoshop Certification",
        description: "Certified expertise in Adobe Photoshop.",
      },
      {
        icon: <SiAdobepremierepro />,
        title: "Adobe Premiere Pro Certification",
        description: "Certified expertise in Adobe Premiere Pro.",
      },
      {
        icon: <SiMdbook />,
        title: "Articulate Certification",
        description: "Certified in Articulate for e-learning development.",
      },
      {
        icon: <TfiBlackboard />,
        title: "Blackboard Certification",
        description: "Certified in using Blackboard LMS.",
      },
      {
        icon: <GiRobotGolem />,
        title: "AI Certification (Pursuing)",
        description:
          "Currently pursuing certification in Artificial Intelligence.",
      },
    ],
  },
  {
    title: "Teaching Experience",
    cards: [
      {
        icon: <GiTeacher />,
        title: "Adjunct Professor",
        description:
          "Taught at Florida Memorial University, focusing on design and creative problem-solving.",
      },
      {
        icon: <SiAdobeaftereffects />,
        title: "Technology Educator",
        description:
          "Led Adobe Certified Associate exam training and developed media literacy curriculum at Broward County Public Schools.",
      },
    ],
  },
];

export default function MistoriaPage() {
  return (
    <>
      <QuadGraphicCTA
        title="Mistoria Brown"
        subTitle="Founder & CEO of GOYE LLC, GOYE A.I."
        ctaLink="/contact"
        ctaTitle="Get in touch"
        data={ctaData}
        url="/images/mistoria-3.png"
      />
      <IconCardSection
        alt
        title="Professional Expertise and Achievements"
        url="/images/mistoria-4.png"
        sections={professionalExpertiseAndAchievements}
        imgAlt="stock photo of people at a meeting"
      />
      <IconCardSection
        alt
        title="Academic Credentials and Learning"
        url="/images/mistoria-8.png"
        sections={academicCredentialsAndLearning}
        imgAlt="stock photo of people at a meeting"
      />
      <ImageCTA
        title="At GOYE AI"
        subTitle="We are dedicated to empowering educational and nonprofit entities through strategic and creative communication solutions. We understand that at the heart of every successful initiative lies a compelling narrative. Our goal is to help you define, articulate, and disseminate your unique story to build lasting connections and achieve sustainable engagement."
        ctaLink="/contact"
        ctaTitle={
          <span>
            Get in touch <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/images/mistoria-7.png"
      />
    </>
  );
}
