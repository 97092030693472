import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// ScrollToTop component
function ScrollToTop() {
  // Get the current location
  const { pathname } = useLocation();

  // Use useEffect to run the code every time the pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]); // <-- This ensures it only runs when pathname changes

  return null; // This component doesn't render anything
}

export default ScrollToTop;
