import React, { useState } from "react";

import styled from "styled-components";
import BasicComponenet from "../General/BasicComponenet";

import { FaAddressBook } from "react-icons/fa";

const BREAK_1 = 1200;
const BREAK_2 = 700;

const Title = styled.div`
  font-size: 1.75rem;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding: 20px 20px 10px 0px;
`;

const SectionTitle = styled.div`
  font-size: 1.75rem;
  color: #000;
  font-weight: 600;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${BREAK_1}px;
  margin-bottom: 50px;

  @media (max-width: ${BREAK_2}px) {
    margin-bottom: 0px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 20px;
`;

const Image = styled.img`
  display: flex;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  max-height: 400px;
  border-radius: 5px;

  width: 1200px;

  max-width:100%;

  max-height: 500px; 


  @media (max-width: ${BREAK_1}px) {
    height: 500px;

  @media (max-width: ${BREAK_2}px) {
  }
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;

  max-width: ${BREAK_1}px;
`;

const Card = styled.div`
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  width: 32%;

  margin-top: 30px;

  background-color: #f8f8f8;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: ${BREAK_1}px) {
    width: 48.3%;
  }

  @media (max-width: ${BREAK_2}px) {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const CardTitle = styled.span`
  font-size: 1rem;
  color: #281188;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
`;

const IconContainer = styled.div`
  font-size: 2rem;
  color: #fff;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function IconCardSection({
  title = "Enter title prop...",
  url,
  imgAlt = "Enter image alt prop...",
  sections = [
    {
      title: "Enter Section Title...",
      cards: [
        {
          icon: <FaAddressBook />,
          title: "Enter card title",
          description: "Enter description that will show up on hover",
        },
      ],
    },
  ],
}) {
  return (
    <BasicComponenet>
      <MainContainer>
        <BannerContainer>
          <Image alt={imgAlt} src={url} />
          <Title>{title}</Title>
          <div
            style={{
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.1)",
              height: 1,
            }}
          ></div>
        </BannerContainer>
        <Container>
          {sections.map((section, index) => (
            <SectionContainer key={index}>
              {section.title && <SectionTitle>{section.title}</SectionTitle>}
              <CardContainer>
                {section.cards.map((card, cardIndex) => (
                  <IconHoverCard key={cardIndex} {...card} />
                ))}
              </CardContainer>
            </SectionContainer>
          ))}
        </Container>
      </MainContainer>
    </BasicComponenet>
  );
}

const PopupTitle = styled.h4`
  margin: 10px 0;
  color: #fff;
`;

const PopupDescription = styled.p`
  font-size: 0.9rem;
  color: #fff;
`;

const Popup = styled.div`
  display: block;
  position: absolute;
  background-color: #281188;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin: -20px 0px 0px 0px;
  padding: 60px 50px 50px 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.visible ? 0.95 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : -10)}px);

  min-width: inherit;
  max-width: calc(1200px * 0.24);
  @media (max-width: ${BREAK_1}px) {
    //max-width: calc(100vw * 0.39 - 50px);
  }

  @media (max-width: ${BREAK_2}px) {
    //max-width: calc(100vw * 0.75 - 50px);
  }

  z-index: 9999;

  transition: all 0.3s ease;
`;

const IconHoverCard = ({ title, icon, description, link, hover = true }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      onClick={() => {
        if (link) {
          window.location.href = link;
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {hover && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: -50,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              borderRadius: 100,
              backgroundColor: "#281188",
              width: 60,
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconContainer>{icon}</IconContainer>
          </div>
        </div>
      )}
      {!hover && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#fff",
            marginTop: -20,
            marginBottom: 20,
            paddingBottom: 20,
          }}
        >
          <div>{icon}</div>
        </div>
      )}
      <CardTitle>{title}</CardTitle>
      {hover && <div style={{ padding: 20 }}>{description}</div>}
      {false && hover && (
        <Popup visible={isHovered}>
          <PopupTitle>{title}</PopupTitle>
          <PopupDescription>{description}</PopupDescription>
        </Popup>
      )}
    </Card>
  );
};
