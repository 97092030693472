import React from "react";

import styled from "styled-components";
import BasicComponenet from "../General/BasicComponenet";

const BREAK_1 = 1200;
const BREAK_2 = 700;

const Title = styled.div`
  font-size: 1.75rem;
  color: #281188;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  padding-top: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-bottom: 75px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  width: 30.2%;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;

  align-items: center;

  @media (max-width: ${BREAK_1}px) {
    width: 44.9%;
  }

  @media (max-width: ${BREAK_2}px) {
    width: 100%;
  }
`;

const Avatar = styled.img`
  border-radius: 50px;
  background-color: #fafafa;
  width: 80px;
  height: 80px;
  margin-left: 20px;
  object-fit: cover;
`;

const CardTitle = styled.span`
  font-size: 1rem;
  color: #281188;
  font-weight: 700;
  padding-left: 20px;
`;

const CardDescription = styled.span`
  font-size: 1rem;
  color: #000;
  padding-left: 20px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 20px;
`;

const SectionTitle = styled.div`
  font-size: 1rem;
  color: #000;
  font-weight: bold;
  text-align: left;
`;

const CardInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPopup = styled.div`
  position: absolute;
  margin: auto;
  margin-left: -10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  display: ${({ visible }) => (visible ? "block" : "none")};
  animation: fadeIn 0.3s;
  max-width: 90vw;
  width: 400;
  z-index: 10;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  margin-bottom: 100px;

  @media (max-width: ${BREAK_2}px) {
    width: 90vw;
  }
`;

const PopupContent = styled.div`
  background-color: #fff;
  margin: 10px;
  padding: 10px;
  z-index: 10;
`;

const LargeAvatar = styled.img`
  width: 100%;
  background-color: #fff;
  height: 300px;
  object-fit: cover;
  background-color: #fafafa;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 5px 5px 0px 0px;
`;

const PopupTitle = styled.h3`
  color: #281188;
  margin-top: 0px;
  padding-top: 0px;
`;

const PopupSubtitle = styled.h4`
  color: #281188;
  margin-top: -15px;
`;

const PopupDescription = styled.p`
  color: #333;
  margin-top: -15px;

  font-size: 14px;
`;

export default function CollaboratorsSection({
  title = "Enter title prop...",
  subtitle = "Enter subtitle prop...",
  sections = [
    {
      title: "Section Title",
      cards: [
        {
          title: "Card Title",
          description:
            "Enter a description for this card using the description field.",
        },
        {
          title: "Card Title",
          description:
            "Enter a description for this card using the description field.",
        },
        {
          title: "Card Title",
          description:
            "Enter a description for this card using the description field.",
        },
        {
          title: "Card Title",
          description:
            "Enter a description for this card using the description field.",
        },
        {
          title: "Card Title",
          description:
            "Enter a description for this card using the description field.",
        },
        {
          title: "Card Title",
          description:
            "Enter a description for this card using the description field.",
        },
      ],
    },
  ],
}) {
  const [hoveredCard, setHoveredCard] = React.useState(null);

  return (
    <BasicComponenet>
      <Container>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        <SectionContainer>
          {sections.map((section) => (
            <>
              <SectionTitle>{section.title}</SectionTitle>
              <CardContainer>
                {section.cards.map((card) => (
                  <Card
                    onMouseEnter={() => setHoveredCard(card)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <Avatar
                      src={
                        "https://api.dicebear.com/7.x/adventurer/svg?" +
                        card.attributes
                      }
                      alt={card.title}
                    />
                    <CardInformation>
                      <CardTitle>{card.title}</CardTitle>
                      <CardDescription>{card.description}</CardDescription>
                    </CardInformation>
                    <StyledPopup visible={hoveredCard === card}>
                      {/* Popup content */}
                      {card.largeImageSrc && (
                        <LargeAvatar
                          src={"/collab/" + card.largeImageSrc}
                          alt={card.title}
                        />
                      )}
                      <PopupContent>
                        <PopupTitle>
                          {card.link ? (
                            <a href={card.link}>{card.title}</a>
                          ) : (
                            card.title
                          )}
                        </PopupTitle>
                        <PopupSubtitle>{card.description}</PopupSubtitle>
                        {card.fullDescription && (
                          <PopupDescription>
                            {card.fullDescription}
                          </PopupDescription>
                        )}
                      </PopupContent>
                    </StyledPopup>
                  </Card>
                ))}
              </CardContainer>
            </>
          ))}
        </SectionContainer>
      </Container>
    </BasicComponenet>
  );
}
