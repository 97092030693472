import React from "react";
import IconCardSection from "../components/Cards/IconCardSection";
import { FaAppStore, FaArrowRight } from "react-icons/fa";
import { MdCastForEducation } from "react-icons/md";
import { GiComputing } from "react-icons/gi";
import { HiChatAlt2 } from "react-icons/hi";
import { FaChildReaching } from "react-icons/fa6";
import { RiParentFill } from "react-icons/ri";

import ImageCTA from "../components/Headers/ImageCTA";
import DualGraphicCTA from "../components/General/DualGraphicCTA";

const ctaData = [
  {
    title: "Customized AI-Driven Learning Tools",
    description:
      "Explore our curated selection of educational applications, each powered by AI to tailor the learning experience to individual needs. Discover tools that enhance learning efficiency and cater to diverse learning styles.",
    link: "View AI-Driven Educational Tools",
  },
  {
    title: "AI-Enhanced Teaching Tools",
    description:
      "Discover a wide range of downloadable resources and articles designed to integrate AI technology in teaching. These tools are specifically tailored to enhance the educational experience, offering unique, adaptive learning solutions for diverse classroom settings.",
    link: "Disover AI Teaching Tools",
  },
  {
    title: "Edtech Resource Hub",
    description:
      "Your one-stop destination for the latest in educational technology. Access comprehensive articles, case studies, and resources aimed at revolutionizing teaching methodologies. Ideal for educators seeking to incorporate innovative tech solutions in their teaching.",
    link: "Explore Edtech Resources",
  },
];

const sections = [
  {
    cards: [
      {
        icon: <GiComputing />,
        title: "Brown vs Education Podcast",
        description:
          "This podcast is coming soon and will be released here in the coming weeks.",
      },
      {
        icon: <FaChildReaching />,
        title: "Youth Development Resources",
        description:
          "This section is a work in progress and will arrive in the coming weeks",
      },
    ],
  },

  {
    title: "Reccomended articles",
    cards: [
      {
        icon: <HiChatAlt2 />,
        title: "AI Policy",
        description:
          "This section is a work in progress and will arrive in the coming weeks",
      },
      {
        icon: <MdCastForEducation />,
        title: "Edtech",
        description:
          "This section is a work in progress and will arrive in the coming weeks",
      },
      {
        icon: <RiParentFill />,
        title: "Teacher Proffesional Development",
        description:
          "This section is a work in progress and will arrive in the coming weeks",
      },
      {
        icon: <FaAppStore />,
        title: "Top Rated Classroom Applications",
        description:
          "This section is a work in progress and will arrive in the coming weeks",
      },
    ],
  },
];

export default function ResourcesPage() {
  return (
    <>
      <DualGraphicCTA
        title="Resources"
        ctaLink="/contact"
        ctaTitle="Get in touch"
        data={ctaData}
      />
      <IconCardSection
        title="Resources For Teachers"
        url="/pexel/pexels-935943.jpg"
        sections={sections}
        imgAlt="two logos side by side, left is for GetLit, right is for Shop 1907"
      />
      <ImageCTA
        title="Want to chat?"
        subTitle="We are dedicated to empowering educational and nonprofit entities through strategic and creative communication solutions. We understand that at the heart of every successful initiative lies a compelling narrative. Our goal is to help you define, articulate, and disseminate your unique story to build lasting connections and achieve sustainable engagement."
        ctaLink="/contact"
        ctaTitle={
          <span>
            Get in touch <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/pexel/pexels-jopwell-2422293.jpg"
      />
    </>
  );
}
