import React from "react";

import styled from "styled-components";
import BasicComponenet from "../General/BasicComponenet";

const BREAK_2 = 800;

const Title = styled.div`
  font-size: 2rem;
  color: #fff;
  font-weight: 600;
  padding: 50px 50px 10px 50px;

  text-align: center;

  @media (max-width: ${BREAK_2}px) {
    font-size: 1.25rem;
    padding: 30px 30px 10px 30px;
  }
`;

const ColorBackground = styled.div`
  background-color: #281188;
  width: 100%;

  border-radius: 0 5px 5px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  @media (max-width: ${BREAK_2}px) {
    border-radius: 0px 0px 5px 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${BREAK_2}px) {
    flex-direction: column;
  }
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: #fff;
  padding: 0px 50px 50px 50px;
  
  @media (max-width: ${BREAK_2}px) {
    font-size: 1rem;
    padding: 0px 30px 30px 30px;
  }
`;

export default function TextOnlyHeader({
  title = "Enter title prop",
  subtitle,
}) {
  return (
    <BasicComponenet>
      <Container>
        <ColorBackground>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </ColorBackground>
      </Container>
    </BasicComponenet>
  );
}
