// src/Routes.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Navbar/Footer";
import ScrollToTop from "./components/Navbar/ScrollToTop";
import ServicesPage from "./pages/Services";
import AIServicesPage from "./pages/AIServices";
import Contact from "./pages/Contact";
import AboutPage from "./pages/About";
import NoPage from "./pages/404";
import CollaboratorsPage from "./pages/Collaboratos";
import MistoriaPage from "./pages/Mistoria";
import ResourcesPage from "./pages/Resources";
import JoinPage from "./pages/Join";

const RoutesConfig = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <div style={{ paddingTop: 100 }} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/join" element={<JoinPage />} />
        <Route path="/ai" element={<AIServicesPage />} />
        <Route path="/collab" element={<CollaboratorsPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/mistoria" element={<MistoriaPage />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default RoutesConfig;
