import React from "react";
import ImageCTA from "../components/Headers/ImageCTA";

import { FaArrowRight } from "react-icons/fa";
import ContactForm from "../components/Previus/Contact";
import TextOnlyHeader from "../components/Headers/TextOnlyHeader";
import DualGraphicCTA from "../components/General/DualGraphicCTA";

const ctaData = [
  {
    title: "Customized AI-Driven Learning Tools",
    description:
      "Explore our curated selection of educational applications, each powered by AI to tailor the learning experience to individual needs. Discover tools that enhance learning efficiency and cater to diverse learning styles.",
    link: "View AI-Driven Educational Tools",
  },
  {
    title: "Education Tailored for the Digital Era",
    description:
      "Our commitment to educational excellence is reflected in our AI-driven applications. Designed to adapt to various learning styles, these tools ensure a personalized and effective educational journey, fitting within diverse budgetary needs.",
  },
  {
    title: "Enhancing Outreach with Integrated Platforms",
    description:
      "We specialize in developing comprehensive platforms that merge seamlessly with multiple communication channels. From social media to direct messaging, our platforms are designed to maximize community engagement and enhance the impact of outreach and fundraising efforts.",
  },
];

export default function Home() {
  return (
    <>
      <TextOnlyHeader
        title="Our Pledge"
        subtitle={
          "To elevate your narrative. At GOYE Service Group, we recognize the power of a story well told. Our services are carefully crafted to capture and communicate the essence of your organization, ensuring that your unique message not only reaches but resonates with your audience. We blend the art of storytelling with strategic communications to foster enduring connections that propel your mission forward."
        }
      />
      <DualGraphicCTA
        ctaLink="https://calendly.com/go--sqa"
        ctaTitle="Schedule an appointment now"
        data={ctaData}
      />
      <ContactForm />
      <ImageCTA
        title="Interested in learning more?"
        subTitle="We understand that learning is a personal experience. That's why our offerings are fully customizable to meet the diverse needs of every student and educator. We cater all our services to match the needs of our clients."
        ctaLink="/services"
        ctaTitle={
          <span>
            Our services <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/pexel/pexels-alexander-suhorucov-6457506.jpg"
      />
    </>
  );
}
