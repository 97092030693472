import React from "react";

import styled from "styled-components";
import BasicComponenet from "../General/BasicComponenet";

const BREAK_1 = 1200;
const BREAK_2 = 1200;

const Image = styled.img`
  display: flex;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  width: 100%;
  max-height: 500px;

  @media (max-width: ${BREAK_1}px) {
    height: 300px;
  }

  @media (max-width: ${BREAK_2}px) {
  }
`;

const Title = styled.div`
  font-size: 1.75rem;
  color: #fff;
  font-weight: 600;
  padding: 50px 50px 10px 50px;

  @media (max-width: ${BREAK_2}px) {
    font-size: 1.25rem;
    padding: 30px 30px 10px 30px;
  }
`;

const ColorBackground = styled.div`
  background-color: #281188;
  width: 100%;

  border-radius: 0 0px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  @media (max-width: ${BREAK_2}px) {
    border-radius: 0px 0px 5px 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${BREAK_2}px) {
  }
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: #fff;
  padding: 0px 50px 30px 50px;
  @media (max-width: ${BREAK_2}px) {
    font-size: 1rem;
    padding: 0px 30px 30px 30px;
  }
`;

const CTAButton = styled.a`
  font-size: 1rem;
  color: #fff;
  margin: 0px 50px 50px 50px;
  border-radius: 5px;
  padding: 15px;
  background-color: #fff;
  color: #000;
  font-weight: 600;
  width: 115px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  @media (max-width: ${BREAK_2}px) {
    font-size: 1rem;
    margin: 0px 30px 30px 30px;
  }

  transition: all 0.3s;

  &:hover {
    transform: translateY(2px);
    cursor: pointer;
    font-size: 1.05rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    width: 125px;
  }

  text-decoration: none;
`;

export default function ImageCTA({
  title = "Enter title prop",
  subTitle = "Enter subtitle prop...",
  ctaLink = "/contact",
  ctaTitle = "Get in touch",
  url = "https://img.freepik.com/free-vector/page-found-concept-illustration_114360-1869.jpg",
  alt = "Enter image alt text...",
}) {
  return (
    <BasicComponenet>
      <Container>
        <Image alt={alt} src={url}></Image>
        <ColorBackground>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          <CTAButton href={ctaLink}>{ctaTitle}</CTAButton>
          <div style={{ height: 50 }} />
        </ColorBackground>
      </Container>
    </BasicComponenet>
  );
}
