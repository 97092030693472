import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { AiFillCloseCircle } from "react-icons/ai";

const BREAK_1 = 768;
const BREAK_2 = 500;

const Nav = styled.nav`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #281188;
  backdrop-filter: blur(5px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: all 0.3s;
  height: 100px;
  position: fixed; // Make the navbar fixed
  top: 0; // Stick it to the top
  z-index: 1000; // Ensure it is above other elements

  @media (max-width: ${BREAK_1}px) {
    justify-content: space-between;
  }
`;

const Logo = styled.div`
  padding: 0px 0px 0px 40px;

  @media (max-width: ${BREAK_2}px) {
    padding: 0px 0px 0px 25px;
  }

  a {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
  }

  transition: all 0.3s;

  justify-content: center;

  img {
    width: 50px;
    height: 50px;
  }
`;

const Menu = styled.ul`
  display: flex;
  list-style: none;
  padding: 20px;

  @media (max-width: ${BREAK_1}px) {
    position: absolute;
    top: -20px;
    right: 0px;
    width: 50vw;
    flex-direction: column;
    background-color: #281188;
    border-radius: 5px 0 0 5px;
    transform: ${(props) =>
      props.openMenu ? "translateX(0)" : "translateX(100%)"};
    gap: 20px;
    backdrop-filter: blur(5px);
    transition: all 0.3s;

    height: 100vh;

    // Add some shadow for depth
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);

    // Rounded corners
    border-radius: 0 0 0 15px;

    display: ${(props) => (props.openMenu ? "flex" : "none")};

    z-index: 1001; // Ensure the menu is above the navbar when open
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: ${BREAK_1}px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    padding: 10px;
    align-items: center;
    margin-right: 25px;
  }

  @media (max-width: ${BREAK_2}px) {
    margin-right: 10px;
  }
`;

const MenuItem = styled.li`
  margin-left: 1rem;
  color: white;

  // Targeting the Link component
  a {
    color: ${(props) => (!props.active ? "#fff" : "rgba(255,255,255,0.75)")};

    padding-bottom: ${(props) => (props.active ? "35px" : "0px")};

    padding-left: ${(props) => (props.active ? "20px" : "none")};
    padding-right: ${(props) => (props.active ? "20px" : "none")};
    margin-left: ${(props) => (props.active ? "-20px" : "0px")};
    margin-right: ${(props) => (props.active ? "-20px" : "0px")};

    text-decoration: ${(props) => (props.active ? "underline" : "none")};

    font-weight: bold;

    &:hover {
      // You can also style the hover state
      color: rgba(255, 255, 255, 0.75); // For example, change color on hover
    }

    font-weight: 500;
    font-size: 1rem;
  }

  &:hover {
    // You can also style the hover state
    transform: translateY(-1px);
  }
`;

const CloseIcon = styled.div`
  display: none;

  @media (max-width: ${BREAK_1}px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: #fff;

    transition: all 0.3s;

    &:hover {
      color: #fa541c;
      transform: translateY(-1px);
    }
  }
`;

const MenuBlur = styled.div`
  backdrop-filter: ${(props) => (props.openMenu ? "blur(5px)" : "blur(0px)")};

  //transition: all 0.1s;

  height: 100vh;
  width: ${(props) => (props.openMenu ? "50vw" : "0vw")};

  @media (max-width: ${BREAK_1}px) {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const closeMenu = (e) => {
      if (openMenu && !document.querySelector("ul").contains(e.target)) {
        setOpenMenu(false);
      }
    };

    window.addEventListener("mousedown", closeMenu);
    return () => {
      window.removeEventListener("mousedown", closeMenu);
    };
  }, [openMenu]);

  return (
    <Nav>
      <Logo>
        <Link to="/" onClick={() => setOpenMenu(false)}>
          <span style={{ marginLeft: 0 }}>GOYE AI</span>
          <img
            src="/logos/bird-1.png"
            style={{
              height: 45 * 1.25,
              width: 52 * 1.25,
              marginLeft: -10,
            }}
            alt="Logo for GOYE AI"
          />
        </Link>
      </Logo>
      <MenuIcon onClick={() => setOpenMenu(!openMenu)}>
        <div
          style={{
            width: "25px",
            height: "4px",
            borderRadius: 5,
            backgroundColor: "#fff",
          }}
        ></div>
        <div
          style={{
            width: "25px",
            height: "4px",
            borderRadius: 5,
            backgroundColor: "#fff",
          }}
        ></div>
        <div
          style={{
            width: "25px",
            height: "4px",
            borderRadius: 5,
            backgroundColor: "#fff",
          }}
        ></div>
      </MenuIcon>
      <MenuBlur openMenu={openMenu} />
      <Menu openMenu={openMenu}>
        <CloseIcon onClick={() => setOpenMenu(!openMenu)}>
          <AiFillCloseCircle size={30} />
        </CloseIcon>
        <MenuItem active={location.pathname === "/home"}>
          <NavLink to="/home" onClick={() => setOpenMenu(false)}>
            Home
          </NavLink>
        </MenuItem>
        <MenuItem active={location.pathname === "/about"}>
          <NavLink to="/about" onClick={() => setOpenMenu(false)}>
            About
          </NavLink>
        </MenuItem>
        <MenuItem active={location.pathname === "/services"}>
          <NavLink to="/services" onClick={() => setOpenMenu(false)}>
            Services
          </NavLink>
        </MenuItem>
        <MenuItem active={location.pathname === "/ai"}>
          <NavLink to="/ai" onClick={() => setOpenMenu(false)}>
            AI
          </NavLink>
        </MenuItem>
        <MenuItem active={location.pathname === "/collab"}>
          <NavLink to="/collab" onClick={() => setOpenMenu(false)}>
            Collaborators
          </NavLink>
        </MenuItem>
        <MenuItem active={location.pathname === "/resources"}>
          <NavLink to="/resources" onClick={() => setOpenMenu(false)}>
            Resources
          </NavLink>
        </MenuItem>
        <MenuItem active={location.pathname === "/join"}>
          <NavLink to="/join" onClick={() => setOpenMenu(false)}>
            Join Our Team
          </NavLink>
        </MenuItem>
        <MenuItem active={location.pathname === "/contact"}>
          <NavLink to="/contact" onClick={() => setOpenMenu(false)}>
            Contact
          </NavLink>
        </MenuItem>
      </Menu>
    </Nav>
  );
};

export default Navbar;
