// Import required libraries
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// Styled Components
const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const BottomText = styled.div`
  a {
    text-decoration: none;
    font-size: 1rem;
    color: black;
  }
`;

const BottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

// Footer Component
const Footer = () => {
  return (
    <FooterContainer>
      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#a8a8a8",
        }}
      />
      <BottomSection>
        <BottomDiv>
          <div style={{ textAlign: "center", fontSize: "1.25rem" }}>
            © 2023 GOYE LLC
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BottomText>
              <Link to="https://www.termsfeed.com/live/5bc0e5b0-2efa-459d-97a8-0c32b6c117c2">
                Privacy Policy{" "}
              </Link>
            </BottomText>
            <BottomText>|</BottomText>
            <BottomText>
              <Link to="https://www.termsfeed.com/live/2e5e5d82-0164-4af9-8929-b1fa7152caa3">
                Terms of Service
              </Link>
            </BottomText>
          </div>
        </BottomDiv>
      </BottomSection>
    </FooterContainer>
  );
};

export default Footer;
