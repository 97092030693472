import React from "react";

export default function JoinPage() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 20,
      }}
    >
      <a href="  https://docs.google.com/forms/d/e/1FAIpQLSc3-_cnwqfLQeSDDEqMN24l-I5hKLyowGD7DsOvk1qqKzbGAg/viewform?embedded=true">
        Not seeing the form?
      </a>
      <iframe
        title="Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSc3-_cnwqfLQeSDDEqMN24l-I5hKLyowGD7DsOvk1qqKzbGAg/viewform?embedded=true"
        style={{
          height: "85vh",
          width: "100%",
          border: "none",
        }}
      >
        Loading form...
      </iframe>
    </div>
  );
}
