import React from "react";
import TextCardContainer from "../components/Cards/TextCardContainer";
import ImageHeaderAlt from "../components/Headers/ImageHeaderAlt";
import QuadGraphicCTA from "../components/General/QuadGraphicCTA";
import ImageCTA from "../components/Headers/ImageCTA";

import { FaArrowRight } from "react-icons/fa";
import ImageHeaderGrey from "../components/Headers/ImageHeaderGrey";
import BasicComponent from "../components/General/BasicComponenet";
import DualGraphicCTA from "../components/General/DualGraphicCTA";

const cards = [
  {
    title: "Personalized Learning",
    description:
      "We believe that education thrives when it is tailored to the individual's unique learning path. Every solution we create is designed to cater to the diverse needs of educators and learners.",
  },
  {
    title: "Design-Driven Innovation",
    description:
      "At the heart of our work is the belief that thoughtful design leads to impactful learning experiences. We are committed to a methodology that combines aesthetics, functionality, and user experience in every educational tool and strategy we develop.",
  },
  {
    title: "Social Justice Commitment",
    description:
      "Recognizing the transformative power of education, we embed principles of equity and inclusion in every aspect of our work, striving to create learning environments that offer equal opportunities for all.",
  },
  {
    title: "Collaborative Approach",
    description:
      "Our team is built on the foundation of partnership — with educators, within communities, and among our staff. We know that the best solutions come from working together, harnessing collective wisdom to meet the challenges of education today.",
  },
  {
    title: "Long-term Engagement",
    description:
      "Our strategies go beyond immediate results. We focus on sustainable growth and enduring relationships, ensuring that the brands and solutions we develop resonate with our clients and they’re audiences for years to come.",
  },
  {
    title: "Sustainable Impact",
    description:
      "We prioritize long-lasting, positive change. Our focus is on sustainable solutions that benefit society and the environment, ensuring our educational and nonprofit initiatives have a meaningful and enduring impact.",
  },
];

const ctaData = [
  {
    title: "Adaptable for Every Learner",
    description:
      "We understand that learning is a personal experience. That's why our offerings are fully customizable to meet the diverse needs of every student and educator. We cater all our services to match the needs of our clients.",
  },
  {
    title: "Tailored to Empower",
    description:
      "We believe in the power of education to inspire change. Our programs are crafted to fit every budget without compromising on quality or accessibility. Each project is a step towards achieving our educational aspirations.",
  },
  {
    title: "Achievement Through Innovation",
    description:
      "Our commitment is to guide you to your learning goals with cutting-edge educational technology that adapts to individual needs and promotes collective success.",
  },
];

export default function AboutPage() {
  return (
    <>
      <BasicComponent style={{ marginBottom: -50 }}>
        <span
          style={{
            color: "#281188",
            fontWeight: "bold",
            fontSize: "3rem",
            textAlign: "center",
          }}
        >
          Elevating Education and Nonprofits
        </span>
      </BasicComponent>
      <ImageHeaderGrey
        url={"/pexel/pexels-keira-burton-6146978.jpg"}
        title="The GOYE Commitment"
        subtitle={
          "Welcome to GOYE Service Group — where our mission intertwines with your vision for a more informed and connected educational and nonprofit world. Established in 2011, GOYE Media has evolved into more than a firm media sources; it has become a force for educational transformation. By treating every interaction as a Significant Educational Encounter by Design© (SEED), we plant the seeds for growth and revolution in learning and community impact."
        }
      />
      <DualGraphicCTA ctaLink="/ai" ctaTitle="AI Services" data={ctaData} />
      <ImageHeaderAlt
        url={"/pexel/pexels-katerina-holmes-5905918.jpg"}
        title="Our Vision"
        subtitle="To be the leading provider of innovative educational technology, empowering teachers and learners by transforming educational environments, tools, and methodologies through our dedication to the principles of design, social justice, and collaboration."
      />
      <TextCardContainer
        cards={cards}
        title="Our Core Values"
        subtitle="Guiding Principles for Lasting Change"
      />
      <ImageHeaderGrey
        url={"/pexel/pexels-tima-miroshnichenko-5686105.jpg"}
        title="What sets us apart"
        subtitle={
          "At GOYE, we redefine collaboration in education and nonprofits by connecting them with a network of passionate people, cutting-edge skills, and transformative tools and spaces, driving impactful change."
        }
      />

      <QuadGraphicCTA
        title="Jumpstart a transformative educational journey"
        subTitle="Our learning solutions are designed to seamlessly integrate into your everyday life."
        ctaLink="/contact"
        ctaTitle="Get in touch"
        data={ctaData}
        url="/pexel/pexels-fauxels-3184291.jpg"
      />
      <ImageCTA
        title="Interested in learning more?"
        subTitle="We understand that learning is a personal experience. That's why our offerings are fully customizable to meet the diverse needs of every student and educator. We cater all our services to match the needs of our clients."
        ctaLink="/services"
        ctaTitle={
          <span>
            Our services <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/pexel/pexels-yan-krukau-8197544.jpg"
      />
    </>
  );
}
