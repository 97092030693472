import React from "react";

import styled from "styled-components";
import BasicComponenet from "../General/BasicComponenet";

const BREAK_1 = 1200;
const BREAK_2 = 900;

const Image = styled.img`
  display: flex;
  object-fit: cover;
  border-radius: 0px 0px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  height: 500px;
  width: 100%;

  @media (max-width: ${BREAK_1}px) {
  }

  @media (max-width: ${BREAK_2}px) {

  }
`;

const Title = styled.div`
  font-size: 1.75rem;
  color: #333;
  font-weight: 600;
  padding: 50px 25px 10px 10px;

  @media (max-width: ${BREAK_2}px) {
    font-size: 1.25rem;
    padding: 30px 30px 10px 10px;
  }
`;

const ColorBackground = styled.div`
  background-color: #fff;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  //height: 300px;

  border-radius: 0 5px 5px 0;
  

  @media (max-width: ${BREAK_1}px) {
    //height: 250px;
  }

  @media (max-width: ${BREAK_2}px) {
    border-radius: 0px 0px 5px 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${BREAK_2}px) {
    flex-direction: column;
  }
`;

const SubTitle = styled.div`
  font-size: 1rem;
  color: #000;
  padding: 0px 50px 50px 10px;
  @media (max-width: ${BREAK_2}px) {
    font-size: 1rem;
    padding: 0px 30px 30px 10px;
  }
`;

export default function ImageHeaderGrey({
  title = "Enter title prop",
  url,
  alt = "Enter image alt text...",
  subtitle,
}) {
  return (
    <BasicComponenet>
      <Container>
        <Image alt={alt} src={url}></Image>
        <ColorBackground>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </ColorBackground>
      </Container>
    </BasicComponenet>
  );
}
