import React from "react";
import { FaArrowRight } from "react-icons/fa";
import ImageCTA from "../components/Headers/ImageCTA";
import CollaboratorsSection from "../components/Cards/CollaboratorsSection";
import DualGraphicCTA from "../components/General/DualGraphicCTA";
import IconCardSection from "../components/Cards/IconCardSection";

const ctaData = [
  {
    title: "Pioneering Advanced Education and Nonprofit Solutions",
    description:
      "At GOYE AI, we harness the synergy of app and AI development to revolutionize educational and nonprofit initiatives. Our suite of strategic services is designed to connect diverse talents and empower organizations through innovation. \nWe are your partner in educational and nonprofit excellence, equipping you with strategic tools to foster growth, innovation, and success.",
  },
  {
    title: "Interactive Learning Reinvented",
    description:
      "Each application is embedded with AI that adapts to individual learning styles, providing a customized educational journey for maximum efficacy.",
  },
  {
    title: "Outreach and Fundraising Transformed",
    description:
      "We build comprehensive platforms that integrate seamlessly with multiple communication channels, from social media to direct messaging, ensuring impactful community engagement.",
  },
];

const sections = [
  {
    title: "Educational Research & Learning Science Guides (Contractors)",
    cards: [
      {
        title: "Mistoria Brown",
        description:
          "Founder, President. EdTech, Technology Project Manager, Technology Educator, Learning Product Designer",
        attributes: "seed=17",
        largeImageSrc: "MISTORIAB_MS.jpg",
        link: "https://calendly.com/go--sqa",
      },
      {
        title: "Dr. Andrews",
        description:
          "Educational and Organizational Leadership, Literacy Development and Instruction",
        attributes: "seed=asasdfddfd",
        largeImageSrc: "tal-chakeia-andrews-240x320.jpg",
        link: "https://people.miami.edu/profile/b30f00af3163b8d82a13eaa333f71fb8",
      },
      {
        title: "Dr. Stephina Burton",
        description: "Education Research and Evaluation",
        attributes: "seed=a162ddfgdgdfdfcfdgfcf&hairColor=5D473A&hair=short04",
        largeImageSrc: "STEPHAIN-USE.jpg",
      },
      {
        title: "Ebony Wallace",
        description:
          "STEM, Advance Leadership Development,  Professional Development Guidance & Support",
        attributes: "seed=12aasd&hair=long16&hairColor=120A08&skinColor=96553D",
        largeImageSrc: "E.Wallace.jpg",
        link: "https://ebonysolutions.com",
      },
      {
        title: "NyDah McCoy",
        description:
          "K-5, Language, Literacy, In Multicultural Setting, Education Research",
        attributes: "seed=dddddffsfs&hair=long19",
      },
      {
        title: "Dr. Mary Avalos",
        description:
          "NSF & DOE Grant Funded, Education Theory and Practice, Education Research and Evaluation",
        attributes: "seed=30&hairColor=f1cc8f",
        largeImageSrc: "Mary_Avalos.png",
        link: "https://people.miami.edu/profile/980f4686def1636317d148973855acb1",
      },
    ],
  },
  {
    title: "Advisory Partners Youth Development",
    cards: [
      {
        title: "Sandra Rennie",
        description: "High School - Computer Science",
        attributes:
          "seed=17&hair=long17&skinColor=B6846C&glasses=variant02&glassesProbability=100",
        largeImageSrc: "sandra-renee.png",
      },
      {
        title: "Marcia Notkins",
        description: "High School Business",
        attributes: "seed=19&hairColor=444444",
        largeImageSrc: "NOTKINS.JPEG",
      },
      {
        title: "Rebecca Smith",
        description: "Middle School - Business",
        attributes: "seed=22&hairColor=333333",
        largeImageSrc: "Rebecca-Smith-USE.jpg",
      },
      {
        title: "Markia Cungham",
        description: "K-5 Educator",
        attributes:
          "seed=12a2asd&hair=long09&hairColor=120A08&skinColor=96553D",
      },
      {
        title: "Sonja Sherman",
        description: "Broward Schools District  Administrator",
        attributes: "seed=12",
        largeImageSrc: "sonja-sherman.png",
      },
      {
        title: "Michelle Mcnab",
        description: "6th -12th Literacy Coach & JFG Lead",
        attributes: "seed=12aasd&hair=long18&hairColor=120A08&skinColor=96553D",
        largeImageSrc: "MCNAB.PNG",
      },
    ],
  },
  {
    title: "Technology Advisor (Contractors)",
    cards: [
      {
        title: "Nelson James",
        description: "Senior Learning Designer, Leaning Product Manager",
        attributes:
          "seed=12345674244gfdh34234g532344343153352&hairColor=444444&skinColor=9E5622",
        largeImageSrc: "Nelson.jpeg",
        link: "https://www.linkedin.com/in/nelsonajames/",
      },
      {
        title: "Jamari King",
        description: "Content Creation (Social Media & Videographer)",
        attributes: "seed=33eidbddfdfddddddfjssdsd&hair=short19",
        largeImageSrc: "j_king.png",
      },
      {
        title: "Paul VanMetre",
        description: "Full-Stack Developer - Native & Web",
        attributes:
          "skinColor=f2d3b1&hairColor=744A1D&mouth=variant09&glasses=variant02&glassesProbability=100",
        largeImageSrc: "paul-vanmetre.png",
        link: "https://www.linkedin.com/in/paul-vanmetre-855ba0235/",
      },
    ],
  },
  {
    title: "Business Operations",
    cards: [
      {
        title: "Shaterica Thompson",
        description: "HR, Accounting Management",
        attributes: "seed=ddd&hairColor=120A08",
        largeImageSrc: "Thompson.jpg",
      },
    ],
  },
];

const companies = [
  {
    cards: [
      {
        icon: (
          <img
            style={{ height: 130, width: 200, objectFit: "cover" }}
            src="/companies/company-1.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "SKILLBUILD",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 130, width: 130, objectFit: "cover" }}
            src="/companies/company-2.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "The City Of Miami Parks & Recreation",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 130, width: 130, objectFit: "cover" }}
            src="/companies/company-3.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "University Of Miami",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 200 }}
            src="/companies/ymcajfif.jfif"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "The YMCA",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 150 }}
            src="/companies/company-5.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "North Broward Prepratory School",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 125, width: 250 }}
            src="/companies/company-6.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "Imagine Charter School At Weston",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 150 }}
            src="/companies/company-7.jpg"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "Broward County Public Schools",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 150 }}
            src="/companies/company-8.jpg"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "Florida Memorial University",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 150 }}
            src="/companies/company-9.jpeg"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "Educational Bootcamp",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 250 }}
            src="/companies/company-10.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "City of Fort Lauderdale Parks & Recreation",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 130 }}
            src="/companies/company-11.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "Beach",
        description: "",
        hover: false,
      },
      {
        icon: (
          <img
            style={{ height: 150, width: 200 }}
            src="/companies/company-12.png"
            alt="logo of company GetLit, the letters GL with an orange background"
          />
        ),
        title: "Break Through Miami",
        description: "",
        hover: false,
      },
    ],
  },
];

export default function CollaboratorsPage() {
  return (
    <>
      <CollaboratorsSection
        title="Our Collaborators"
        subtitle="Our dedicated team of Advisors and Contractors is committed to our mission, working diligently to select the finest learning environments, tools, and products to ensure the highest quality of services and educational experiences."
        sections={sections}
      />
      <IconCardSection
        title="Who We Have Work With"
        url="/pexel/pexels-christina-morillo-1181622.jpg"
        sections={companies}
        imgAlt="art of a globe"
      />
      <DualGraphicCTA
        title="How We Can Support You"
        subTitle=""
        ctaLink="/contact"
        ctaTitle="Get in touch"
        data={ctaData}
        url="/images/cta-1.png"
      />
      <ImageCTA
        title="Want the full story?"
        subTitle="Discover our journey and the values that drive us. Learn more about our owner and her dedication to shaping the future of education and nonprofit success."
        ctaLink="/mistoria"
        ctaTitle={
          <span>
            Learn more about Mistoria{" "}
            <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/collab/mistoria-more.png"
      />
    </>
  );
}
