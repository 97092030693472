import React from "react";
import IconCardSection from "../components/Cards/IconCardSection";
import { FaAddressBook, FaArrowRight, FaBrain } from "react-icons/fa";
import { HiChatBubbleLeftEllipsis } from "react-icons/hi2";
import { LuCircuitBoard } from "react-icons/lu";
import { FaSchoolCircleCheck } from "react-icons/fa6";
import { LiaDigitalTachographSolid } from "react-icons/lia";
import { MdSupportAgent } from "react-icons/md";
import { MdComputer } from "react-icons/md";
import { MdLightbulbOutline } from "react-icons/md";
import { FaBullhorn } from "react-icons/fa";
import { MdTouchApp } from "react-icons/md";
import { FaPhotoVideo } from "react-icons/fa";
import { MdOutlineManageAccounts } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";

import ImageCTA from "../components/Headers/ImageCTA";
import DualGraphicCTA from "../components/General/DualGraphicCTA";

const ctaData = [
  {
    title: "At GOYE Service Group",
    description:
      "GOYE excels in enriching educational and nonprofit endeavors with our unparalleled ability to link them to a diverse pool of human talent, advanced skill sets, and empowering environments, all designed to catalyze growth and success.",
  },
  {
    title: "Tailored to Empower",
    description:
      "We believe in the power of education to inspire change. Our programs are crafted to fit every budget without compromising on quality or accessibility. Each project is a step towards achieving our educational aspirations.",
  },
  {
    title: "Achievement Through Innovation",
    description:
      "Our commitment is to guide you to your learning goals with cutting-edge educational technology that adapts to individual needs and promotes collective success.",
  },
];

const sections = [
  {
    title: "Professional Development",
    cards: [
      {
        icon: <FaBrain />,
        title: "Comprehensive Professional Development Programs",
        description:
          "Tailored to meet the unique needs of educators and administrators, our professional development programs focus on enhancing teaching skills, leadership abilities, and educational strategies. These programs are designed to adapt to the evolving educational landscape, ensuring that educators and administrators stay ahead of the curve.",
      },
      {
        icon: <FaAddressBook color="#fff" style={{ color: "#fff" }} />,
        title: "Specialized Workshops and Seminars",
        description:
          "Our workshops and seminars delve into specific areas of interest, providing in-depth knowledge and practical skills. Topics range from innovative teaching methods to effective school management strategies, all aimed at enhancing the educational experience.",
      },
      {
        icon: <HiChatBubbleLeftEllipsis />,
        title: "Continuous Learning Support",
        description:
          "We believe in ongoing growth. Beyond initial training sessions, we offer continuous support and resources to ensure educators and administrators can continually develop their skills and apply new strategies effectively in their roles.",
      },
    ],
  },
  {
    title: "Technology Training",
    cards: [
      {
        icon: <LuCircuitBoard />,
        title: "Cutting-Edge Technology Integration",
        description:
          "Our technology training focuses on seamlessly integrating the latest educational technologies into the classroom. We provide hands-on training to educators on how to effectively use these tools to enhance learning and engagement.",
      },
      {
        icon: <FaSchoolCircleCheck />,
        title: "Customized Tech Solutions for Schools",
        description:
          "Understanding that each school has unique needs, we offer customized technology solutions. From selecting the right educational software to hardware setup and maintenance, we ensure that technology enhances, rather than hinders, the educational process.",
      },
      {
        icon: <LiaDigitalTachographSolid />,
        title: "Digital Literacy and Safety",
        description:
          "In the digital age, it's crucial for both educators and students to be digitally literate and safe online. Our training covers essential aspects of digital literacy, including cyber safety, digital ethics, and the responsible use of technology in education.",
      },
      {
        icon: <MdSupportAgent />,
        title: "Ongoing Tech Support and Updates",
        description:
          "Technology is always evolving, and so is our support. We provide ongoing assistance and updates to ensure that schools are always equipped with the latest and most efficient technology tools and know-how.",
      },
    ],
  },
];

const sections2 = [
  {
    cards: [
      {
        icon: <MdComputer />,
        title: "Professional Development",
        description:
          "We offer targeted professional development opportunities designed to enhance the skills and knowledge of nonprofit professionals. Our programs focus on leadership, effective management, fundraising strategies, and more, ensuring that your team is equipped to meet the challenges and opportunities in the nonprofit sector.",
      },
      {
        icon: <MdLightbulbOutline />,
        title: "Strategy and Planning",
        description:
          "Our comprehensive approach to communication includes establishing a clear, effective plan to convey your message. We deeply engage with your mission to create a brand narrative that truly resonates. Our process involves thorough research and close partnership, ensuring that the language and visuals we develop accurately reflect your organization's identity and purpose.",
      },
      {
        icon: <FaBullhorn />,
        title: "Social Media and Marketing Services",
        description:
          "Our expert team, including videographers, graphic designers, social media specialists, and writers, creates content that embodies your brand's essence. We design marketing materials that not only boost your visibility but also engage and enlighten your target audience, fostering a deeper connection with your community.",
      },
      {
        icon: <MdTouchApp />,
        title: "Community Engagement & Research",
        description:
          "We embrace the transformative power of community. Our strategies focus on active participation and dialogue with community members, valuing their insights while highlighting the impact of your work. We strive to create meaningful connections between your organization and the communities you serve.",
      },
      {
        icon: <FaPhotoVideo />,
        title: "Content Production",
        description:
          "Our approach to content production encompasses everything from podcasts to visual storytelling. We align all communication efforts with your strategic goals, dedicated to conceptualizing and producing content that strengthens your presence in the educational and nonprofit landscape.",
      },
      {
        icon: <MdOutlineManageAccounts />,
        title: "Project Management",
        description:
          "Our project management services ensure that your initiatives are executed efficiently and effectively. We oversee the planning, execution, and delivery of projects, focusing on timely completion and adherence to your strategic objectives. Our team works closely with you to manage resources, timelines, and stakeholder communication, ensuring every project contributes to your organization's success.",
      },
    ],
  },
];

const sections3 = [
  {
    cards: [
      {
        icon: <CgWebsite />,
        title: "Custom Website Design",
        description:
          "Tailored Solutions: We specialize in creating custom websites that reflect the unique identity and mission of each educational and nonprofit organization. Our designs are not only visually appealing but also user-friendly and accessible. Responsive Design: Understanding the importance of mobile accessibility, our websites are designed to be fully responsive, ensuring a seamless experience on all devices, from desktops to smartphones.",
      },
      {
        icon: <FaMobileAlt />,
        title: "App Development",
        description:
          "Custom Mobile Apps: Our team develops custom mobile applications tailored to the specific needs of educational and nonprofit organizations. These apps are designed to enhance engagement, provide valuable resources, and streamline processes. User Experience Focused: We prioritize user experience in our app development, ensuring that apps are intuitive, easy to navigate, and provide a positive user experience. Integration with Existing Systems: Our apps are designed to integrate seamlessly with existing databases and systems, enhancing the overall digital ecosystem of the organization.",
      },
      {
        icon: <MdSupportAgent />,
        title: "Ongoing Support and Maintenance",
        description:
          "Reliable Support: We provide ongoing technical support and maintenance to ensure your website and app remain up-to-date and perform at their best. Continuous Improvement: Our services include regular updates and improvements based on user feedback and emerging technology trends.",
      },
      {
        icon: <SiGoogleclassroom />,
        title: "E-Learning Platforms",
        description:
          "Interactive Learning Tools: For educational organizations, we create dynamic e-learning platforms that support interactive and engaging learning experiences. Analytics and Reporting: These platforms come equipped with analytics and reporting tools to track learner progress and effectiveness of the content.",
      },
    ],
  },
];

export default function ServicesPage() {
  return (
    <>
      <IconCardSection
        alt
        title="Strategic Services for Nonprofit Organizations"
        url="/pexel/pexels-fauxels-3184296.jpg"
        sections={sections2}
        imgAlt="stock photo of people at a meeting"
      />
      <DualGraphicCTA ctaLink="/ai" ctaTitle="AI Services" data={ctaData} />
      <IconCardSection
        title="Strategic Services for Schools, Educators, and Administrators"
        url="/pexel/pexels-rdne-stock-project-8052886.jpg"
        sections={sections}
        imgAlt="stock photo of people at a meeting"
      />
      <IconCardSection
        title="Strategic Services for Education and Nonprofit Organizations: Website & App Development"
        url="/pexel/pexels-christina-morillo-1181244.jpg"
        sections={sections3}
        imgAlt="stock photo of people at a meeting"
      />
      <ImageCTA
        title="At GOYE AI"
        subTitle="We are dedicated to empowering educational and nonprofit entities through strategic and creative communication solutions. We understand that at the heart of every successful initiative lies a compelling narrative. Our goal is to help you define, articulate, and disseminate your unique story to build lasting connections and achieve sustainable engagement."
        ctaLink="/contact"
        ctaTitle={
          <span>
            Get in touch <FaArrowRight style={{ marginBottom: -2 }} />
          </span>
        }
        url="/pexel/pexels-christina-morillo-1181676.jpg"
      />
    </>
  );
}
